<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "SnkNews",
		extends: Base,
		data() {
			return {
				Name: "SnkNews",
				hero: {},
				filter: {status:''},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
			this.filter.status=''
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.project'() {
				this.search()
			},
		},
		methods:{
			submitFormHero(e, callback){
	          if(e&&e.btnLoading()) return;
	          this.hero.type = 'hero'
	          this.hero.project = App.$route.params.id
	          BOGen.apirest("/"+this.Name, this.hero, (err, resp)=>{
	            if(e) e.btnUnloading()
	            if(err){
	              if(err.status == 422){
	                Object.keys(err.responseJSON).forEach((k)=>{
	                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
	                })
	              }
	              return
	            }
	            if(resp.error){
	              if(this.errorFormCallback) return this.errorFormCallback(resp);
	              if(callback) return callback(resp);
	              return Gen.info(resp.message, "danger",1000,'.info-hero')
	            }
	            if(resp.success){
	              if(this.successFormCallback) return this.successFormCallback(resp);
	              if(callback) return callback(resp);
	              return Gen.info(resp.message, "success", 1000,'.info-hero').then(()=>{
	                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
	              })
	            }
	          },"POST")
	        }, 
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Hero</h5>
						<VForm @resp="submitFormHero" method="post">
							<div class="row">
								<div class="col-md-12 info-hero"></div>
								<div class="col-md-6">
									<BoField name="sc_background" mandatory label="Hero Image">
										<Uploader :param="{thumbnail:true}" name="sc_background"
											v-model="hero.sc_background" type="background" uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="sc_title" v-model="hero.sc_title" mandatory></BoField>
									<BoField name="sc_sub_title" mandatory>
										<textarea class="form-control" v-model="hero.sc_sub_title"></textarea>
									</BoField>
								</div>
								<div class="col-md-12 text-right">
									<button type="submit" class="btn btn-rounded btn-info btn-loading">Update Hero</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List </h5>
								</div>
								<div class="col-sm-3">
									<select v-model="filter.status" @change="search()" class="form-control">
										<option value="">-- Filter Status --</option>
										<option value="D">Draft</option>
										<option value="P">Publish</option>
									</select>
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="mpa_title"></SortField>
									</th>
									<th>{{fields.mpa_author}}</th>
									<th>{{fields.mpa_publish_date}}</th>
									<th>Status</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.mpa_title}}</td>
									<td>{{v.mpa_author}}</td>
									<td>{{(v.mpa_publish_date||"").dates("format")}}</td>
									<td>
										<StatusLabel :type="'blog'" :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'">
											<i class="ti-marker-alt"></i>
										</router-link>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'">
											<i class="ti-trash"></i>
										</a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="mb-0 justify-content-end" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-8">
							<BoField name="mpa_title" v-model="row.mpa_title"></BoField>
							<BoField name="mpa_desc">
								<CKEditor name="mpa_desc" v-bind="validation('mpa_desc')" class="form-control" v-model="row.mpa_desc"></CKEditor>
							</BoField>
						</div>
						<div class="col-4">
							<BoField name="mpa_img_thumbnail">
								<Uploader name="mpa_img_thumbnail" v-bind="validation('mpa_img_thumbnail')" :param="{thumbnail:true}" type="newsThumb" uploadType="cropping" v-model="row.mpa_img_thumbnail"></Uploader>
							</BoField>
							<BoField name="mpa_img_detail">
								<Uploader name="mpa_img_detail" v-bind="validation('mpa_img_detail')" :param="{thumbnail:true}" type="newsDetail" uploadType="cropping" v-model="row.mpa_img_detail"></Uploader>
							</BoField>
						</div>
						<div class="col-6">
							<BoField name="mpa_author" v-model="row.mpa_author"></BoField>
							<BoField name="mpa_tags">
								<TagsInput name="mpa_tags" style="display:block" v-model="row.mpa_tags" :attr="validation('mpa_tags')"></TagsInput>
							</BoField>
							<BoField name="mpa_meta_description" >
								<textarea name="mpa_meta_description" v-bind="validation('mpa_meta_description')" class="form-control" rows="6" v-model="row.mpa_meta_description"></textarea>
							</BoField>
						</div>
						<div class="col-6">
							<BoField name="mpa_publish_date">
								<DatePicker name="mpa_publish_date" v-bind="validation('mpa_publish_date')" v-model="row.mpa_publish_date" placeholder="Publish Date"></DatePicker>
							</BoField>
							<BoField name="mpa_meta_keyword">
								<TagsInput name="mpa_meta_keyword" style="display:block" v-model="row.mpa_meta_keyword"
									:attr="validation('mpa_meta_keyword')"></TagsInput>
							</BoField>
							<BoField name="mpa_is_active">
								<div class="row">
									<radio name="mpa_is_active" v-model="row.mpa_is_active" option="P"
										:attr="validation('mpa_is_active')">Publish</radio>
									<radio name="mpa_is_active" v-model="row.mpa_is_active" option="D">Draft</radio>
								</div>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-rounded btn-loading">Save News</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>